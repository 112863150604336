<template>
  <div style="white-space: nowrap">
    <span>{{ $t(value) }}</span>
    <q-icon
      v-if="value !== $t(value)"
      name="lightbulb_outline"
      style="font-size: 1.5em"
      color="primary"
    >
      <q-tooltip
        transition-show="scale"
        transition-hide="scale"
        content-class="bg-primary"
      >
        {{ value }}
      </q-tooltip>
    </q-icon>
  </div>
</template>

<script>
export default {
  name: "ProReportI18nTdField",
  props: {
    value: null,
  },
};
</script>
